import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import BlogPostPreviewList from '../../components/blog-post-preview-list'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop } from '../../containers'
import Hero from '../../components/hero'
import PortableText from '../../components/portableText'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { DiscussionEmbed } from 'disqus-react'
import { MdHelpOutline, MdHelp } from "react-icons/md";
import ReactTooltip from 'react-tooltip';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import houseHackingImg from '../../assets/house-hacking-2x2.png'
import Sidebar from '../../components/Sidebar'
import { GraphDesktop, GraphMobile, AxisLabel } from '../../components/GraphComponents'
import { ThemedAccordion, FinancesTableWrapper, FinancesTableGrid, FinancesTableRow } from '../../components/CustomAccordion'
import { GumroadAd } from '../../components/GumroadAd'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query HouseHackingCalcPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      metaImage {
        ...SanityImage
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          authors{
            author{
              name
              id
            }
          }
          mainImage {
            asset{
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const H2 = styled.h2`
  font-weight: 300;
`

const CalculatorPanel = styled.div`
  margin: 0 auto;
  /* padding: 24px; */
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  max-width: 1200px;

  @media (max-width: 500px) {
    border: none;
    border-radius: 0px;
  }
`

const CalculatorGrid = styled.div`
  padding: 24px;
  /* margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px; */
  display: grid;
  /* grid-template-columns: minmax(300px, 1fr) 3fr; */
  grid-template-columns: 1fr;
  grid-gap: 48px;
  align-items: start;
  margin-bottom: 48px;
  /* max-width: 1200px; */

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 0px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`

const GroupLabel = styled.h3`
  display: block;
  width: 100%;
  color: ${(props) => props.theme.theme.text.primary};
  text-transform: uppercase;
  margin: 0 0 24px 0;
  font-weight: 500;
  font-size: 0.9rem;
`

const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const FlexInput = styled.div`
  margin: 0px 12px 12px 0;
  width: 100%;
`;

const InputItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 4px;
  align-items: center;
  margin: 0 0 0 0;
`;

const AllocationGroup = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 0 12px 0;
  width: 100%;

  h3 {
    text-transform: uppercase;
    margin: 0 0 8px 0;
    font-weight: 500;
  }
`;

const AllocationGroupGray = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  color: ${(props) => props.theme.theme.text.primary};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.border.tertiary};
  }
`;

const AllocationGroupRowTwoCol = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 60px;
  grid-gap: 18px;
`;

const AllocationGroupRed = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentRed};
  border: 1px solid ${(props) => props.theme.theme.colors.red};
  color: ${(props) => props.theme.theme.colors.red};
  /* margin: 8px 8px 0 8px; */

  .result-value {
    font-weight: 500;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.red};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.red};
  }
`;

const AllocationGroupGreen = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  color: ${(props) => props.theme.theme.colors.green};
  /* margin: 8px 8px 0 8px; */

  .result-value {
    font-weight: 500;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const SliderBlack = styled(Slider)`
  &.MuiSlider-root {
    color: ${(props) => props.theme.theme.colors.black};
  }
`;

const InputGroup = styled.div`
  box-sizing: border-box;
  display: grid;
  align-content: start;
  /* display: flex; */
  flex-wrap: wrap;
  /* padding: 8px 8px 0 8px; */
  /* border-radius: 8px; */
  /* padding: 12px 12px 0px 12px; */
  /* border: 1px solid ${(props) => props.theme.theme.border.tertiary}; */
  grid-template-columns: 1fr;
  /* grid-gap: 24px; */
  margin: 0 0 0px 0;

  /* h3 {
    color: ${(props) => props.theme.theme.text.primary};
    text-transform: uppercase;
    margin: 0 0 12px 0;
    font-weight: 500;
  } */

  @media (max-width: 500px) {
    /* padding: 24px 0px 0 0px; */
  }
`;

const CustomReactTooltip = styled(ReactTooltip)`
  max-width: 50vw;
`;

const ResultsPanel = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  padding: 12px;
  display: flex;
  flex-wrap: wrap;

  .label {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 0 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 12px 0 0;
  }

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }

  @media (max-width: 500px) {
    margin: 0px;
  }
`;

const ResultsGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;

  @media (max-width: 500px) {
  }
`;

const ColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) ) ;
  grid-gap: 24px;
`

const ContainerContent = styled.div`
  max-width: ${props => props.theme.theme.contentWidths.content};
`

const ContainerTwoCol = styled(ContainerMain)`
  display: grid;
  grid-template-columns: ${props => props.theme.theme.contentWidths.content} 1fr;
  grid-gap: 48px;
  padding-right: 0px;
  align-items: start;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding-right: 24px;
  }
`

const Image = styled.img`
  width: 100%;
`

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Month #: </strong>
          {payload[0].payload.month}
        </CardText400>

        <CardText400>
          <strong>Monthly payment: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.monthlyPayment.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Payment towards interest: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.interestPortion.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Payment towards principal: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.principalPortion.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Loan Balance: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.loanBalance.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const HouseHackingCalc = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const [ interestRate, setInterestRate ] = useState(3.5)
  const [ timeLength, setTimeLength ] = useState(30)
  const [ purchasePrice, setPurchasePrice ] = useState(200000)
  const [ downPayment, setDownPayment ] = useState(40000)
  // const [ principal, setPrincipal ] = useState(50000)
  const [ annualCompoundingPeriods, setAnnualCompoundingPeriods ] = useState(12)
  const [ annualPaymentPeriods, setAnnualPaymentPeriods ] = useState(12)
  const [ monthlyRentIncome, setMonthlyRentIncome ] = useState(1000)
  const [ propertyTaxRate, setPropertyTaxRate ] = useState(1)
  const [ annualHomeInsurance, setAnnualHomeInsurance ] = useState(1400)
  const [ annualMaintenance, setAnnualMaintenance ] = useState(2000)
  const [ vacancyRate, setVacancyRate ] = useState(7)
  const [ rentSavings, setRentSavings ] = useState(1000)

  const principal = purchasePrice - downPayment

  const monthlyPropertyTax = (propertyTaxRate / 100) * (1/12) * purchasePrice
  const monthlyExpenses = monthlyPropertyTax + (annualHomeInsurance/12) + (annualMaintenance/12)
  const averageMonthlyIncome = (monthlyRentIncome*(1- (vacancyRate/100)))

  const rate = (Math.pow( 1 + ((interestRate/100)/annualCompoundingPeriods), annualCompoundingPeriods/annualPaymentPeriods )) - 1
  const nper = annualPaymentPeriods * timeLength
  const payment = principal * ( ( rate * Math.pow( 1 + rate, nper) ) / ( Math.pow( 1 + rate, nper) - 1) )

  const monthlyCashFlow = averageMonthlyIncome - payment - monthlyExpenses

  const calcLoanData = () => {
    let loanData = []
    for(let i = 0; i <= nper; i++){

      if(!i){

        const paymentInterest = principal * rate
        const paymentPrincipal = payment - paymentInterest

        loanData.push({
          month: i,
          monthlyPayment: 0,
          interestPortion: 0,
          principalPortion: 0,
          loanBalance: parseFloat(principal),
          totalInterest: 0
        })
      } else {
        if(i < nper){
          const paymentInterest = loanData[i-1].loanBalance * rate
          const paymentPrincipal = parseFloat(payment) - parseFloat(paymentInterest)

          loanData.push({
            month: i,
            monthlyPayment: payment,
            interestPortion: paymentInterest,
            principalPortion: paymentPrincipal,
            loanBalance: parseFloat(loanData[i-1].loanBalance) - paymentPrincipal,
            totalInterest: loanData[i-1].totalInterest + paymentInterest
          })
        } else {
          const paymentInterest = loanData[i-1].loanBalance * rate
          const paymentPrincipal = payment - paymentInterest

          loanData.push({
            month: i,
            monthlyPayment: payment,
            interestPortion: paymentInterest,
            principalPortion: paymentPrincipal,
            loanBalance: 0,
            totalInterest: loanData[i-1].totalInterest + paymentInterest
          })
        }

      }
    }
    return loanData
  }

  const loanData = calcLoanData()

  // const netWorthImpact = 100
  const netWorthImpact = monthlyCashFlow + rentSavings + loanData[1].principalPortion
  console.log(netWorthImpact)


  console.log(loanData)

  return (
    <Layout>
      <SEO
        title={'House Hacking Calculator'}
        description={'Accelerate your path to financial freedom by being a live-in landlord'}
        keywords={site.keywords}
      />
      <CustomReactTooltip />
      <ContainerMain>
        <h1>House Hacking Calculator</h1>
        <H2>Accelerate your path to financial freedom by being a live-in landlord</H2>

      </ContainerMain>

      <CalculatorPanel>
        <CalculatorGrid>
          <form>
          <ColumnsWrapper>
            <InputGroup>
              <GroupLabel>Mortgage</GroupLabel>
              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Home Purchase Price"
                  value={purchasePrice}
                  onChange={event => setPurchasePrice(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the purchase price of your property.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Down Payment"
                  value={downPayment}
                  onChange={event => setDownPayment(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the down payment you make on your home purchase. Down payments on owner-occupied homes
                  are typically between 3.5% and 20%.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                    required
                    id="outlined-required"
                    label="Length of Loan (in Years)"
                    value={timeLength}
                    onChange={event => setTimeLength(event.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                  <MdHelp
                    size="1.2rem"
                    data-tip={`This is the length in years over which you will pay back your loan. Most home loans are 15 or 30 year loans.`}
                  />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                    required
                    id="outlined-required"
                    label="Interest Rate"
                    value={interestRate}
                    onChange={event => setInterestRate(event.target.value)}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatPercentage,
                    }}
                  />
                  <MdHelp
                    size="1.2rem"
                    data-tip={`This is the interest rate of your loan.`}
                  />
                </InputItem>
              </FlexInput>
            </InputGroup>

            <InputGroup>
              <GroupLabel>Additional Expenses</GroupLabel>
              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Property Tax Rate"
                  value={propertyTaxRate}
                  onChange={event => setPropertyTaxRate(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`Your home's property tax rate varies by location and can range from around 0.28% to nearly 2.5%.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "275px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Annual Homeowners Insurance"
                  value={annualHomeInsurance}
                  onChange={event => setAnnualHomeInsurance(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`The annual cost of homeowners insurance in the USA is about $1400, but it varies by state and home value.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Annual Maintenance Costs"
                  value={annualMaintenance}
                  onChange={event => setAnnualMaintenance(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`Homeowners spend an average of $1,100 annually on maintenance. A popular rule is to budget 1% of your
                  home's purchase price for ongoing maintenance, or $1 per square foot. Your maintenance costs can vary depending on the age of your home.`}
                />
                </InputItem>
              </FlexInput>
            </InputGroup>

            <InputGroup>
              <GroupLabel>Rent Income</GroupLabel>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Rent Income (Monthly)"
                  value={monthlyRentIncome}
                  onChange={event => setMonthlyRentIncome(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the monthly income that you expect to earn from renting out part of your property.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Vacancy Rate"
                  value={vacancyRate}
                  onChange={event => setVacancyRate(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the percentage of time that you expect your unit(s) to be unoccupied.
                  The average vacancy rate is the USA is about 7% but varies by city.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Rent Savings (per month)"
                  value={rentSavings}
                  onChange={event => setRentSavings(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the amount that you would pay in rent if you did not house-hack.`}
                />
                </InputItem>
              </FlexInput>

            </InputGroup>



            {/* <ResultsPanel>
              <GroupLabel>Monthly</GroupLabel>
              <ResultsGroup>
                <span className="label">Mortgage payment (Principal and Interest): </span>
                <span className="result-value">
                  <NumberFormat
                    displayType={"text"}
                    value={payment.toFixed(2)}
                    thousandSeparator
                    prefix="$"
                  />
                </span>
              </ResultsGroup>

              <ResultsGroup>
                <span className="label">Total interest: </span>
                <span className="result-value">
                  <NumberFormat
                      displayType={"text"}
                      value={loanData[nper].totalInterest.toFixed(2)}
                      thousandSeparator
                      prefix="$"
                  />
                </span>
              </ResultsGroup>
            </ResultsPanel> */}
          </ColumnsWrapper>

          <ColumnsWrapper>
            <AllocationGroupRed>
              <Label>
                Principal and Interest
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your monthly mortgage payment, which includes the principal you are paying down and interest.`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={payment.toFixed(2)}
                  thousandSeparator
                  prefix="$"
                  suffix=" /month"
                />
              </span>
            </AllocationGroupRed>

            <AllocationGroupRed>
              <Label>
                Additional Expenses
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your total monthly expenses not including your mortgage payment.`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={monthlyExpenses.toFixed(2)}
                  thousandSeparator
                  prefix="$"
                  suffix=" /month"
                />
              </span>
            </AllocationGroupRed>

            <AllocationGroupGreen>
              <Label>
                Average income
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your expected rental income adjusted for vacancy.`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={averageMonthlyIncome.toFixed(2)}
                  thousandSeparator
                  prefix="$"
                  suffix=" /month"
                />
              </span>
            </AllocationGroupGreen>

            <AllocationGroupGray>
              <Label>
                Net cash flow
                <MdHelp
                  size="1.2rem"
                  data-tip={`(Net cash flow) = (average income) - (principal & interest) - (additional expenses).
                  This is your monthly cash flow after you have covered all expenses. It is OK for this to be negative!`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={(monthlyCashFlow).toFixed(2)}
                  thousandSeparator
                  prefix="$"
                  suffix=" /month"
                />
              </span>
            </AllocationGroupGray>

            <AllocationGroupGray>
              <Label>
                Principle Paydown
                <MdHelp
                  size="1.2rem"
                  data-tip={`(This is the amount of your first mortgage payment that is going towards your home equity (or principle).
                  Note that as you get closer to paying off your mortgage, an increasing portion of your mortgage payment goes towards
                  principle (see Loan Amortization Schedule below).`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={(loanData[1].principalPortion).toFixed(2)}
                  thousandSeparator
                  prefix="$"
                  suffix=" /month"
                />
              </span>
            </AllocationGroupGray>

            <AllocationGroupGray>
              <Label>
                Overall Net Worth Impact
                <MdHelp
                  size="1.2rem"
                  data-tip={`(Overall net worth impact) = (net cash flow) + (principle paydown) + (rent savings).
                  This is the total monthly impact that house hacking is having on your net worth compared to if
                  you were to rent instead. Remember, by house hacking you no longer have to pay rent, and instead you use
                  rent income from tenants to help cover your mortgage and build home equity.`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={(netWorthImpact).toFixed(2)}
                  thousandSeparator
                  prefix="$"
                  suffix=" /month"
                />
              </span>
            </AllocationGroupGray>

          </ColumnsWrapper>

          </form>

            {/* <GraphDesktop>
              <ResponsiveContainer width='100%' height={500} >
                <AreaChart data={loanData}
                    margin={{top: 10, right: 0, left: 50, bottom: 30}}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="month" label={{value: "Time (months)", position: "insideBottom", offset: -10}} />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="left"
                  />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="right"
                    orientation="right"
                  />
                  <Tooltip
                    content={<CustomTooltip/>}
                  />
                  <Legend verticalAlign="top" height={50} />
                  <Area type='monotone' name="Loan Balance ($)" dataKey='loanBalance' stroke='#3AC732' fill='#CFF0CD' yAxisId="left" />
                  <Area type='monotone' name="Monthly payment ($)" dataKey='monthlyPayment' stroke='#000' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards interest ($)" dataKey='interestPortion' stroke='#FF1733' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards principal ($)" dataKey='principalPortion' stroke='#1274E0' fillOpacity={0} yAxisId="right"/>
                </AreaChart>
              </ResponsiveContainer>
            </GraphDesktop> */}

            {/* <GraphMobile>
              <ResponsiveContainer width='100%' height={500} >
                <AreaChart data={loanData}
                    margin={{top: 10, right: 0, left: 10, bottom: 30}}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="month" label={{value: "Time (months)", position: "insideBottom", offset: -10}} />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="left"
                    mirror
                  />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="right"
                    orientation="right"
                    mirror
                  />
                  <Tooltip
                    content={<CustomTooltip/>}
                  />
                  <Legend verticalAlign="top" height={110} />
                  <Area type='monotone' name="Loan Balance ($)" dataKey='loanBalance' stroke='#3AC732' fill='#CFF0CD' yAxisId="left" />
                  <Area type='monotone' name="Monthly payment ($)" dataKey='monthlyPayment' stroke='#000' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards interest ($)" dataKey='interestPortion' stroke='#FF1733' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards principal ($)" dataKey='principalPortion' stroke='#1274E0' fillOpacity={0} yAxisId="right"/>
                </AreaChart>
              </ResponsiveContainer>
            </GraphMobile> */}
        </CalculatorGrid>

        <ThemedAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Loan Amortization Schedule</AccordionSummary>
          <AccordionDetails>
            <FinancesTableWrapper>
              <FinancesTableGrid>
                <FinancesTableRow className="labels-row">
                  <span>Month #</span>
                  <span>Payment</span>
                  <span>Interest</span>
                  <span>Principal</span>
                  <span>Loan Balance</span>
                </FinancesTableRow>

                {loanData.map((dataPoint, index) => {
                  if (!index) {
                    return (
                      <FinancesTableRow>
                        <span>{dataPoint.month}</span>
                        <span>{dataPoint.monthlyPayment}</span>
                        <span>{dataPoint.interestPortion}</span>
                        <span>{dataPoint.principalPortion}</span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.loanBalance.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                      </FinancesTableRow>
                    );
                  } else {
                    return (
                      <FinancesTableRow>
                        <span>{dataPoint.month}</span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.monthlyPayment.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.interestPortion.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.principalPortion.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.loanBalance.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                      </FinancesTableRow>
                    );
                  }
                })}
              </FinancesTableGrid>
            </FinancesTableWrapper>
          </AccordionDetails>
        </ThemedAccordion>

      </CalculatorPanel>

      <GumroadAd />

      <ContainerTwoCol>
        <ContainerContent>
          <h2>What is House Hacking?</h2>
          <p>
            House hacking is where you purchase a single- or multi-family property and then live in the property while renting
            out a room or unit to help cover your mortgage expenses. The practice of house hacking is also known as being an
            owner-occupied landlord or live-in landlord. The key advantage to house hacking is that your tenants effectively
            help cover your mortgage payment which can reduce or even eliminate your housing expense. Since housing is the
            biggest monthly expense for most people, house hacking can help you to dial up your savings rate and reach financial
            independence sooner.
          </p>

          <h2>Advantages of House Hacking</h2>
          <p>
            <ul>
              <li>
                <strong>Being an owner occupant allows you to get the best financing terms.</strong> Generally, being an owner
                occupant allows you to get a lower interest rate than purchasing a property for investment purposes. If you
                choose to move out at some point and keep your property as a long-term rental, you can keep the owner-occupied
                loan in place.
              </li>
              <li>
                <strong>Smaller down payments are possible.</strong> Again, since you are purchasing the property with an
                owner-occupied loan, you are able to make a down payment as low as 0 to 5% the purchase price by using a VA
                loan or FHA loan. Loans for an investment property typically require a down payment of 20 to 25%.
              </li>
              <li>
                <strong>You likely only have to live there for one year.</strong> In general, the requirement for getting an
                owner-occupied loan is that you maintain occupancy for at least one year. This depends on the terms of your
                loan, so you should read the fine print before assuming the occupancy requirement. If you were to purchase a
                property with an owner-occupied loan and then did not live in the property for the first year, you would be
                committing mortgage fraud, which is a serious offense that can lead to fines and even prison time.
              </li>
            </ul>
          </p>

          <h2>House Hacking approaches</h2>
          <p>
            There is no one specific recipe for house hacking - it can be done successfully with several different types of
            properties. There are a wide variety of approaches that you can take depending on what kind of down payment you
            can afford and your comfort level with living with housemates. From a high level we can divide the strategies into
            two groups: Single-Family and Multi-Family. The 2x2 diagram below shows how the different strategies compare with
            respect to privacy and profitability.
          </p>

          <Image src={houseHackingImg} style={{maxWidth: '600px'}}/>

          <h3>Single Family Home</h3>
          <p>
            Compared to a multi-family property, a single-family home is generally going to have a lower purchase price which
            means you can start house-hacking with a lower down-payment. Single family homes are more widespread than
            multi-family properties, which can be quite rare to come on the market depending on your city. A common approach
            to house hacking with a single-family home is to rent out one or more of your extra bedrooms to housemates and
            share the common spaces. The drawback to this is that you sacrifice some of your privacy as you are effectively
            "living with roommates." Make sure to check you local zoning laws as many cities limit the number of non-related
            people that can live in the same household. Strategies include:

            <ul>
              <li>
                <strong>Long-term rent by the room.</strong> With this approach, you find long-term tenants for individual
                rooms in your house. By having your tenants sign a lease you can limit how often you need to fill vacant rooms.
              </li>
              <li>
                <strong>Short-term rent by the room (Airbnb).</strong> While similar to the approach above, renting out your
                rooms on a short-term basis offers the potential to make a bit more income and also the flexibility to have
                your place to yourself when you want. The downside is that being an Airbnb host requires time and effort and
                you would have more strangers coming and going.
              </li>
            </ul>

            In high cost of living areas like the SF Bay Area, Seattle, and Denver, house-hacking a single family home is
            popular because there is a large market of young people looking to rent by the room, and multi-family properties
            can be prohibitively expense.
          </p>

          <h3>Multi-Family</h3>
          <p>
            House-hacking with a multi-family property offers its own set of pros and cons. Low down payment loans like VA or
            FHA loans can be used to house-hack multi-family properties like duplexes, triplexes, fourplexes, or single-family
            homes with Accessory Dwelling Units (ADU). The drawbacks to the multi-family approach is that multi-family properties
            are more expensive (requiring a higher down-payment) and often harder to find on the market. On the plus side,
            multi-family house-hacking offers more privacy to you as the landlord and also offers the potential for more
            cashflow if you choose to move out and rent out all the units in the future. Strategies include:

            <ul>
              <li>
                <strong>Long-term rent the other unit.</strong> The simplest multi-family approach is to live in one unit and
                rent out the other(s) to tenants on a long-term lease. Having long term leases gives you some predictability
                about vacancies, and is fairly hands-off aside from maintenance.
              </li>
              <li>
                <strong>Short-term rent the other unit (Airbnb).</strong> Since "entire places" command a premium on Airbnb,
                renting your other unit on a short-term basis can allow you to earn more each month than a long-term rental.
                The drawback is the time and effort required to be an Airbnb host.
              </li>
              <li>
                <strong>Rent the other unit and rent a room in your unit.</strong> The ultimate frugal house-hack is to both
                rent out the other unit (either short- or long-term) while at the same time renting out one of the rooms in
                your unit (also either short- or long-term). This may be the most profitable house-hacking strategy because
                it combines the rent-by-the-room model with the multi-family approach.
              </li>
            </ul>
          </p>

          <h2>Overall House Hacking strategy</h2>
          <p>
            From a high-level, many real estate investors find house-hacking attractive because it allows them to
            accumulate a portfolio of rental properties with relatively low money down because of the benefits of
            owner-occupied loans that I previously mentioned. The common overall strategy for building wealth through house
            hacking is as follows:
            <ol>
              <li>Buy your first house hack (HH#1).</li>
              <li>Live in HH#1 for at least one year while using the rent savings to help save up cash for another down payment.</li>
              <li>Buy your second house hack using this down payment (HH#2).</li>
              <li>Move out of HH#1 and place tenants to fully rent it out. With it fully rented, ideally it is providing a positive net cash flow.</li>
              <li>Live in HH#2 for at least one year. Use the rent savings from HH#2 and positive net cash flow from HH#1 to save up cash for another down payment.</li>
              <li>Repeat.</li>
            </ol>
          </p>

          <p>
            As you can see, this house-hacking strategy creates a snowball effect on your net worth where with each house
            hack you do, you create more positive cash flow. Even if you only want to do one house-hack and then get your
            own place, once you move out you can place tenants who will be helping to pay off your mortgage while your
            property also likely appreciates in value.
          </p>

          <p>
            <a href="https://www.instagram.com/thefiguy/?hl=en" target="_blank">Craig Curelop</a> is a golden example of this strategy executed to perfection. He started house-hacking in Denver,
            Colorado in 2017 and in just a few years, he was able to reach financial independence from the cash flow that his
            house hacks provided combined with the rapidly-appreciating Denver real estate market. To learn more about Craig's
            strategy you can check out his book: <a href="https://amzn.to/3fZZlx5" target="_blank">The House Hacking Strategy: How to Use Your Home to Achieve Financial Freedom</a>.
          </p>

          <h2>Drawbacks to House Hacking</h2>
          <p>
            While house hacking can be an amazing strategy with the right planning, it is not without its drawbacks:
            <ul>
              <li>House hacking involves some sacrifice depending on which stage of life you are in. If you want to house-hack a
                single-family home, then you need to be comfortable with living with roommates. House-hacking a multi-family gives
                you more personal space as you can have the whole unit to yourself, but it still may be a step down in standard of
                living if you have a spouse and children and are used to living in a nice single family home.
              </li>
              <li>House hacking means becoming a landlord, and this brings with it several responsibilities. You have to stay on
                top of maintenance of your property, carefully vet tenants, and replace tenants when they move out.
              </li>
              <li>
                House-hacking does have some financial risks. While they are impossible to predict, black swan events like
                the Covid-19 pandemic can impact the ability of your tenants to pay rent, which could leave you on the hook
                for your mortgage payments with no rental income. Additionally, purchasing real estate with a low down
                payment means investing with high amounts of leverage. While leverage is great when prices are appreciating,
                it could also leave you owing more than the property is worth if your local market has a downturn.
              </li>
            </ul>
          </p>


        </ContainerContent>

        <Sidebar />
      </ContainerTwoCol>

      <Backdrop>
        <ContainerMain>
          <DiscussionEmbed
              shortname={process.env.GATSBY_DISQUS_NAME}
              config={{
                  identifier: 'house-hacking-calculator'
              }}
          />
        </ContainerMain>
      </Backdrop>
    </Layout>
  )
}

export default HouseHackingCalc
